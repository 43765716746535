import { Pipe, PipeTransform } from "@angular/core";
import { User } from "src/app/interfaces/user";
import { Hour } from "../interfaces/hours";
import { parseCustomDate } from "../helpers/parseDate";

@Pipe({
  name: "filterUserByDateRange",
})
export class FilterUserByDateRangePipe implements PipeTransform {
  transform(
    hours: Hour[],
    user: User,
    startDate?: Date,
    endDate?: Date
  ): Hour[] {
    if (!hours || !user) {
      return [];
    }

    if (startDate && endDate) {
      endDate.setHours(23, 59, 59, 999);

      return hours.filter((hour) => {
        const hourDate = parseCustomDate(hour.createdAt);
        return hourDate >= startDate && hourDate <= endDate;
      });
    }

    return hours;
  }
}
