import { FilterPdfItemsPipe } from "./../pipe/filter-pdf-items.pipe";
import { LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { FilterPipe } from "../pipe/filter.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatRadioModule } from "@angular/material/radio";
import { FilterSubtaskPipe } from "../pipe/filter-subtask.pipe";
import { MatTableModule } from "@angular/material/table";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FilterByDateRangePipe } from "../pipe/filter-by-date-range.pipe";
import { FilterReportNumberPipe } from "../pipe/filter-report-number.pipe";
import { SortReportByNumberPipe } from "../pipe/sort-report-by-number.pipe";
import { FilterDateCalendarPipe } from "../pipe/filter-date-calendar.pipe";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { FilterUserByDateRangePipe } from "../pipe/filterByDateRange.pipe";
import { CalculateSalaryByUserPipe } from "../pipe/calculateSalaryByUser.pipe";
import { SalaryAdminPipe } from "../pipe/SalaryAdmin.pipe";
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
registerLocaleData(localeEs);
import { WebcamModule } from "ngx-webcam";
import { CalculateExtraHoursByDatePipe } from "../pipe/calculateExtraHoursByDate.pipe";

@NgModule({
  declarations: [
    FilterPipe,
    FilterSubtaskPipe,
    FilterByDateRangePipe,
    FilterReportNumberPipe,
    FilterPdfItemsPipe,
    SortReportByNumberPipe,
    FilterDateCalendarPipe,
    FilterUserByDateRangePipe,
    CalculateSalaryByUserPipe,
    SalaryAdminPipe,
    CalculateExtraHoursByDatePipe,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    WebcamModule,
  ],
  exports: [
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule,
    FilterPipe,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    FilterSubtaskPipe,
    MatTableModule,
    MatProgressSpinnerModule,
    FilterByDateRangePipe,
    FilterReportNumberPipe,
    FilterPdfItemsPipe,
    SortReportByNumberPipe,
    FilterDateCalendarPipe,
    MatProgressBarModule,
    FilterUserByDateRangePipe,
    CalculateSalaryByUserPipe,
    SalaryAdminPipe,
    DatePipe,
    WebcamModule,
    CalculateExtraHoursByDatePipe,
  ],
  providers: [
    DatePipe,

    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
  ],
})
export class HelpersModule {}
