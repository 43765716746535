<mat-drawer-container
  *ngIf="isloggedIn(); else withoutNavBar"
  class="example-container"
  autosize
>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <p>Auto-resizing sidenav</p>
  </mat-drawer>

  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <button
        [ngStyle]="routeHome() ? { display: 'none' } : { display: 'block' }"
        mat-icon-button
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="example-spacer"></span>

      <img
        [routerLink]="['/home']"
        src="../assets/navbar/TECNO AIR COLD-01-01 (1).svg"
        class="navbar-logo"
      />
      <button
        mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar-row>

    <mat-menu #menu="matMenu" [overlapTrigger]="true">
      <button [routerLink]="['/home']" mat-menu-item>
        <span>Inicio</span>
      </button>
      <button
        *ngIf="
          getUser()?.clientsPermission ||
          getUser()?.createAssetsPermission ||
          getUser()?.createSubtasksPermission ||
          getUser()?.usersPermission ||
          getUser()?.sendMailsPermission
        "
        [routerLink]="['/maintenance']"
        mat-menu-item
        [matMenuTriggerFor]="maintenance"
      >
        <span>Mantenimiento</span>
      </button>

      <mat-menu #maintenance="matMenu" [overlapTrigger]="true">
        <button
          *ngIf="getUser()?.usersPermission"
          [routerLink]="['/users']"
          mat-menu-item
        >
          <span>Usuarios</span>
        </button>

        <button
          *ngIf="getUser()?.clientsPermission"
          [routerLink]="['/clients']"
          mat-menu-item
        >
          <span>Clientes</span>
        </button>

        <button
          *ngIf="getUser()?.createAssetsPermission"
          [routerLink]="['/assets']"
          mat-menu-item
        >
          <span>Activos</span>
        </button>

        <button
          *ngIf="getUser()?.createSubtasksPermission"
          [routerLink]="['/subtasks']"
          mat-menu-item
        >
          <span>Subtareas</span>
        </button>

        <button
          *ngIf="getUser()?.sendMailsPermission"
          [routerLink]="['/send-mails']"
          mat-menu-item
        >
          <span>Correo electrónico</span>
        </button>
      </mat-menu>

      <button
        *ngIf="getUser()?.superAdmin"
        mat-menu-item
        (click)="openEditHeaderInformationDialog()"
      >
        <span>Mi información</span>
      </button>

      <button
        *ngIf="getUser()?.viewReportPermission"
        [routerLink]="['/report-view']"
        mat-menu-item
      >
        <span>Ver reportes</span>
      </button>

      <button
        *ngIf="getUser()?.createReportPermission"
        [routerLink]="['/report']"
        mat-menu-item
      >
        <span>Crear reporte</span>
      </button>

      <button
        *ngIf="getUser()?.superAdmin"
        [routerLink]="['/calendar']"
        mat-menu-item
      >
        <span>Calendario</span>
      </button>

      <button
        *ngIf="getUser()?.viewHoursPermission"
        [routerLink]="['/hours-report']"
        mat-menu-item
      >
        <span>Reporte de horas por usuarios</span>
      </button>

      <button
        *ngIf="!getUser()?.viewHoursPermission"
        [routerLink]="['/add-hours']"
        mat-menu-item
      >
        <span>Reporte de horas </span>
      </button>

      <button (click)="logout()" mat-menu-item>
        <span>Cerrar sesión</span>
      </button>
    </mat-menu>
  </mat-toolbar>

  <router-outlet></router-outlet>
</mat-drawer-container>

<ng-template #withoutNavBar>
  <main>
    <router-outlet> </router-outlet>
  </main>
</ng-template>
