import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(value: any[], filterString: string, property: string): any[] {
    if (!value || !filterString) {
      return value;
    }

    let filtered: any[] = [];

    for (let data of value) {
      const isNested = property.includes(".");
      let propertyValue;

      if (isNested) {
        propertyValue = property
          .split(".")
          .reduce((acc, part) => acc && acc[part], data);
      } else {
        propertyValue = data[property];
      }

      if (
        propertyValue &&
        propertyValue.toLowerCase().includes(filterString.toLowerCase())
      ) {
        filtered.push(data);
      }
    }

    return filtered;
  }
}
