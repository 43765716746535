export const calculateWidth = () => {
  if (window.innerWidth < 400) {
    return "95vw";
  }
  if (window.innerWidth < 800) {
    return "80vw";
  }
  if (window.innerWidth < 1400) {
    return "40vw";
  }
  if (window.innerWidth >= 1400){
    return "25vw";
  }
  return "auto";
};
