import { Injectable } from "@angular/core";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import { HourService } from "../services/hour.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Card, HourStateModel } from "../interfaces/hours";
import { CameraComponent } from "../pages/add-hour/component/camera/camera.component";
import { CookieService } from "ngx-cookie-service";
import { User } from "../interfaces/user";
import { calculateWidth } from "../helpers/calculate-modal-width";

export class AddHour {
  static readonly type = "[Hour] Add Hour";
  constructor(public card: Card) {}
}

export class FinalizeHour {
  static readonly type = "[Hour] Finalize Hour";
  constructor(public cardId: string, public hourId: string) {}
}

export class RefreshUserCard {
  static readonly type = "[Hours] Refresh User Card";
  constructor(public userId: string) {}
}

@State<HourStateModel>({
  name: "hours",
  defaults: {
    id: null,
    cards: [],
    card: null,
  },
})
@Injectable()
export class HourState {
  constructor(
    private hourService: HourService,
    private snack: MatSnackBar,
    private dialog: MatDialog,
    private cookieService: CookieService
  ) {}

  @Selector()
  static getUserCards({ card }: HourStateModel) {
    return card;
  }

  @Action(AddHour)
  async addHour({}: StateContext<HourStateModel>, {}: AddHour) {
    const user: User = JSON.parse(this.cookieService.get("user"));
    const dialogRef = this.dialog.open(CameraComponent, {
      maxWidth: calculateWidth(),
      width: calculateWidth(),
    });

    dialogRef.afterClosed().subscribe(async (imageUrl: string | null) => {
      if (!imageUrl) {
        this.snack.open("Captura de imagen fallida o cancelada.", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
        return;
      }

      try {
        const existingCard = await this.hourService
          .getCardByUserId(user.id)
          .toPromise();

        if (existingCard) {
          await this.hourService.addHourToCard(existingCard.id, imageUrl);
          this.snack.open(
            "Hora agregada correctamente al card existente.",
            "Ok",
            {
              duration: 3000,
              panelClass: ["snackbar"],
              horizontalPosition: "center",
              verticalPosition: "bottom",
            }
          );
        } else {
          await this.hourService.createCardWithHour(user.id, imageUrl);
          this.snack.open("Card y hora registrados correctamente.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
      } catch (error) {
        this.snack.open("Error al registrar la hora.", "Ok", {
          duration: 3000,
          panelClass: ["snackbar-error"],
          horizontalPosition: "center",
          verticalPosition: "bottom",
        });
      }
    });
  }

  @Action(FinalizeHour)
  async finalizeHour(
    {}: StateContext<HourStateModel>,
    { cardId, hourId }: FinalizeHour
  ) {
    try {
      const dialogRef = this.dialog.open(CameraComponent, {
        maxWidth: calculateWidth(),
        width: calculateWidth(),
      });

      dialogRef.afterClosed().subscribe(async (imageUrl: string | null) => {
        if (!imageUrl) {
          this.snack.open("Captura de imagen fallida o cancelada.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
          return;
        }
        try {
          const finishedAt = new Date().toLocaleString("en-GB");

          await this.hourService.finalizeHour(
            cardId,
            hourId,
            finishedAt,
            imageUrl
          );

          this.snack.open("Salida registrada correctamente.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        } catch (error) {
          this.snack.open("Error al finalizar el día.", "Ok", {
            duration: 3000,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
          });
        }
      });
    } catch (error) {
      console.error("Error finalizing hour:", error);
      this.snack.open("Error al finalizar el día.", "Ok", {
        duration: 3000,
        panelClass: ["snackbar-error"],
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    }
  }
}
