import { Component, HostListener, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MatDialogRef } from "@angular/material/dialog";
import { WebcamImage } from "ngx-webcam";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
const hoursCollection = environment.production ? "hours" : "dev-hours";
import { AngularFireStorage } from "@angular/fire/compat/storage";

@Component({
  selector: "app-camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.less"],
})
export class CameraComponent implements OnInit {
  public webcamImage: WebcamImage | null = null;
  private trigger: Subject<void> = new Subject<void>();
  webcamWidth: number;
  webcamHeight: number;

  constructor(
    public dialogRef: MatDialogRef<CameraComponent>,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.adjustWebcamSize();
  }

  ngOnInit() {}

  triggerSnapshot() {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.uploadImage(webcamImage);
  }

  private async uploadImage(webcamImage: WebcamImage): Promise<void> {
    try {
      const id = this.afs.createId();
      const imageData = webcamImage.imageAsDataUrl;
      const byteString = atob(imageData.split(",")[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const file = new Blob([arrayBuffer], { type: "image/jpeg" });

      const filePath = `${hoursCollection}/${id}.jpg`;
      const fileRef = this.storage.ref(filePath);
      const uploadTask = fileRef.put(file);

      await uploadTask;

      const dowloadURL = await fileRef.getDownloadURL().toPromise();

      this.dialogRef.close(dowloadURL);
    } catch (err) {
      this.dialogRef.close(null);
    }
  }

  get triggerObservable(): Subject<void> {
    return this.trigger;
  }

  @HostListener("window:resize", [])
  onResize() {
    this.adjustWebcamSize();
  }

  private adjustWebcamSize() {
    const screenWidth = window.innerWidth;

    this.webcamWidth = screenWidth > 600 ? 500 : screenWidth - 50;
    this.webcamHeight = this.webcamWidth * 0.75;
  }
}
