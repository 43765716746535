<div class="webcam-container">
  <webcam
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    [width]="webcamWidth"
    [height]="webcamHeight"
    style="width: 100%; height: auto"
  ></webcam>

  <button
    class="mt-10 mb-10"
    mat-raised-button
    color="primary"
    (click)="triggerSnapshot()"
  >
    Capturar foto
  </button>
</div>
