import { Pipe, PipeTransform } from "@angular/core";
import { parseCustomDate } from "../helpers/parseDate";
import { Hour } from "../interfaces/hours";

@Pipe({
  name: "calculateExtraHoursByDate",
})
export class CalculateExtraHoursByDatePipe implements PipeTransform {
  transform(
    hours: Hour[],
    startDate?: Date,
    endDate?: Date
  ): { date: string; hours: string }[] {
    if (!hours) return [];

    const filteredHours = hours.filter((hour) => {
      if (startDate && endDate) {
        const hourDate = parseCustomDate(hour.createdAt);
        return hourDate >= startDate && hourDate <= endDate;
      }
      return true;
    });

    const hoursByDay: { [date: string]: Hour[] } = {};
    filteredHours.forEach((hour) => {
      const dateKey = hour.createdAt.split(", ")[0];
      if (!hoursByDay[dateKey]) {
        hoursByDay[dateKey] = [];
      }
      hoursByDay[dateKey].push(hour);
    });

    const extraHoursByDate: { date: string; hours: string }[] = [];
    Object.entries(hoursByDay).forEach(([date, dailyHours]) => {
      let dailyMinutes = 0;
      let dailyExtraMinutes = 0;

      dailyHours.forEach((hour) => {
        const regularMinutes =
          (hour.regularHours || 0) * 60 + (hour.regularMinutes || 0);
        const extraMinutes =
          (hour.extraHours || 0) * 60 + (hour.extraMinutes || 0);

        if (extraMinutes > 0) {
          dailyExtraMinutes += extraMinutes;
        } else {
          dailyMinutes += regularMinutes;
        }
      });

      if (dailyMinutes > 540) {
        dailyExtraMinutes += dailyMinutes - 480;
      }

      if (dailyExtraMinutes > 0) {
        const extraHours = Math.floor(dailyExtraMinutes / 60);
        const extraMinutes = dailyExtraMinutes % 60;

        const lastHour = dailyHours[dailyHours.length - 1].createdAt;
        extraHoursByDate.push({
          date: lastHour.split(", ")[0],
          hours: `${extraHours} hr (s) y ${extraMinutes} min`,
        });
      }
    });

    extraHoursByDate.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });

    return extraHoursByDate;
  }
}
