import { Pipe, PipeTransform } from "@angular/core";
import { parseCustomDate } from "../helpers/parseDate";

@Pipe({
  name: "SalaryAdmin",
  pure: false,
})
export class SalaryAdminPipe implements PipeTransform {
  transform(
    users: any[],
    cards: { [userId: string]: any },
    searchInitialDate: Date | null,
    searchLastDate: Date | null
  ): { user: any; salary: number }[] {
    if (!users || !cards) return [];

    if (searchLastDate) {
      searchLastDate.setHours(23, 59, 59, 999);
    }

    return users
      .map((user) => {
        const card = cards[user.id];
        if (!card?.hours) return { user, salary: 0 };

        const filteredHours = card.hours.filter((hour: any) => {
          const hourDate = parseCustomDate(hour.createdAt);
          return (
            (searchInitialDate ? hourDate >= searchInitialDate : true) &&
            (searchLastDate ? hourDate <= searchLastDate : true)
          );
        });

        const hoursByDay: { [date: string]: any[] } = {};
        filteredHours.forEach((hour: any) => {
          const dateKey = parseCustomDate(hour.createdAt)
            .toISOString()
            .split("T")[0];
          if (!hoursByDay[dateKey]) {
            hoursByDay[dateKey] = [];
          }
          hoursByDay[dateKey].push(hour);
        });

        const totalSalary = Object.values(hoursByDay).reduce(
          (dayAcc, dailyHours: any[]) => {
            const dailyTotals = dailyHours.reduce(
              (totals, hour) => {
                totals.regularMinutes +=
                  (hour.regularHours || 0) * 60 + (hour.regularMinutes || 0);
                totals.extraMinutes +=
                  (hour.extraHours || 0) * 60 + (hour.extraMinutes || 0);
                return totals;
              },
              { regularMinutes: 0, extraMinutes: 0 }
            );

            if (dailyTotals.regularMinutes > 540) {
              const excessMinutes = dailyTotals.regularMinutes - 480;
              dailyTotals.regularMinutes = 480;
              dailyTotals.extraMinutes += excessMinutes;
            }

            const regularRate = user.regularHoursRate || 0;
            const extraRate = user.extraHoursRate || 0;

            const dailyRegularSalary =
              (dailyTotals.regularMinutes * regularRate) / 60;
            const dailyExtraSalary =
              (dailyTotals.extraMinutes * extraRate) / 60;

            return dayAcc + dailyRegularSalary + dailyExtraSalary;
          },
          0
        );

        return { user, salary: totalSalary };
      })
      .filter(({ salary }) => salary > 0);
  }
}
