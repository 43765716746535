import { Pipe, PipeTransform } from "@angular/core";
import { parseCustomDate } from "../helpers/parseDate";
import { Hour } from "../interfaces/hours";
import { User } from "../interfaces/user";

@Pipe({
  name: "calculateSalary",
})
export class CalculateSalaryByUserPipe implements PipeTransform {
  transform(hours: Hour[], user: User, startDate?: Date, endDate?: Date) {
    if (!hours || !user) {
      return {
        totalSalary: 0,
        totalHours: 0,
        totalExtraHours: 0,
        totalExtraMinutes: 0,
        totalMinutes: 0,
      };
    }

    const filteredHours = hours.filter((hour) => {
      if (startDate && endDate) {
        const hourDate = parseCustomDate(hour.createdAt);
        const inRange = hourDate >= startDate && hourDate <= endDate;

        return inRange;
      }
      return true;
    });

    const hoursByDay: { [date: string]: Hour[] } = {};
    filteredHours.forEach((hour) => {
      const dateKey = parseCustomDate(hour.createdAt)
        .toISOString()
        .split("T")[0];
      if (!hoursByDay[dateKey]) {
        hoursByDay[dateKey] = [];
      }
      hoursByDay[dateKey].push(hour);
    });

    let totalRegularMinutes = 0;
    let totalExtraMinutesGlobal = 0;

    Object.entries(hoursByDay).forEach(([date, dailyHours]) => {
      let dailyRegularMinutes = 0;
      let dailyExtraMinutes = 0;

      dailyHours.forEach((hour) => {
        dailyRegularMinutes +=
          (hour.regularHours || 0) * 60 + (hour.regularMinutes || 0);
        dailyExtraMinutes +=
          (hour.extraHours || 0) * 60 + (hour.extraMinutes || 0);
      });

      if (dailyRegularMinutes > 540) {
        const excessMinutes = dailyRegularMinutes - 480;
        dailyRegularMinutes = 480;
        dailyExtraMinutes += excessMinutes;
      }

      totalRegularMinutes += dailyRegularMinutes;
      totalExtraMinutesGlobal += dailyExtraMinutes;
    });

    const totalCombinedMinutes = totalRegularMinutes + totalExtraMinutesGlobal;
    const totalHours = Math.floor(totalCombinedMinutes / 60);
    const totalMinutes = totalCombinedMinutes % 60;

    const totalExtraHours = Math.floor(totalExtraMinutesGlobal / 60);
    const totalExtraMinutes = totalExtraMinutesGlobal % 60;

    const regularRate = user.regularHoursRate || 0;
    const extraRate = user.extraHoursRate || 0;

    const regularAmount = (totalRegularMinutes * regularRate) / 60;
    const extraAmount = (totalExtraMinutesGlobal * extraRate) / 60;

    const totalSalary = parseFloat((regularAmount + extraAmount).toFixed(2));

    return {
      totalSalary,
      totalHours,
      totalMinutes,
      totalExtraHours,
      totalExtraMinutes,
    };
  }
}
